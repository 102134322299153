.l-site-footer {
  letter-spacing: $letter-spacing;

  &,
  .nav-item > a {
    color: $navbar-dark-color;
    text-decoration: none;
    font-weight: $font-weight-semibold;
  }

  .nav-item {
    display: block;
    padding: 5px 0;
    & > a:hover,
    & > a:focus,
    & > a:active, {
      color: $gray-900;
    }
  }

  .navbar-brand {
    img {
      height: 3.438rem;
      max-width: 50vw;
    }
  }

  .footer-title {
    margin-bottom: 0.9375rem;
  }

  .nav-partners {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 5px 0 0 0;
  }

  .meta-wrapper {
    * {
      color: $gray-900;
    }
  }


}
