.btn {
  letter-spacing: $letter-spacing;
  &[disabled] {
    opacity: 0.4;
  }
}
.btn-sm {
  line-height: $line-height-base;
}
.btn-close {
  background: none;
  width: auto;
  height: auto;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $grid-gutter-width * 1.25;
    height: $grid-gutter-width * 1.25;
    border-radius: $grid-gutter-width * 1.25;
    transform: scale(1);
    transition: $transition-base;
    .icon {
      stroke-width: 2;
    }
  }
  &:hover,
  &:focus {
    span {
      transform: scale(1.2);
    }
  }
}
.dropdown-toggle,
.btn-filter {
  display: flex;
  align-items: center;
  .icon {
    margin-left: 14px;
    stroke-width: 1.6;
  }
  &:hover,
  &:focus {
    .icon {
      stroke: $white;
    }
  }
  &:after {
    display: none;
  }
}
.btn-link {
  color: $dark;
  letter-spacing: $letter-spacing;
  font-weight: $btn-font-weight;
  @include font-size($btn-font-size);
  &:hover,
  &:focus {
    color: $primary;
  }
}
.btn {
  &.show {
    .icon.icon--chevron {
      transform: scaleY(-1);
    }
  }
}
