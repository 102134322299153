* {
  @include font-smoothing(on);

  &::selection {
    color: $body-bg;
    background: lighten($primary, 10%);
  }
}

html {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100%;
}

body {
  word-break: break-word;
}

main {
  flex-shrink: 0;
}


.container-fluid {
  max-width: $container-fluid-max-width;
  padding-right: 1.25rem;
  padding-left: 1.25rem;

  @include media-breakpoint-up(sm) {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }

  @include media-breakpoint-up(xl) {
    padding-left: $grid-gutter-width * 2;
    padding-right: $grid-gutter-width * 2;
  }
}

.bg-none {
  background: none !important;
}

pre {
  overflow: visible;
}

.list-bullet {
  li {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      &:before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: $gray-900;
        margin: 0 10px;
      }
    }
  }
  &.text-danger {
    li:not(:first-child):before {
      background-color: $danger;
    }
  }
}

.stroke-1_4 {
  stroke-width: 1.4;
}
