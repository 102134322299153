.page-widgets {

  [class*='widget-'] {
    &[class*='bg-'] {
      padding-top: 4.375rem;
      padding-bottom: 4.375rem;
      @include media-breakpoint-up(md) {
        padding-top: 6.25rem;
        padding-bottom: 6.25rem;
      }
      @include media-breakpoint-up(xxl) {
        padding-top: 8.75rem;
        padding-bottom: 8.75rem;
      }
    }
    &.bg-light + .bg-light,
    &.bg-white + .bg-white {
      padding-top: 0;
    }
  }
  .widget-call-to-action {
    &.bg-white {
      .card {
        background: $light;
        overflow: hidden;
      }
    }
  }

  #page-intro {
   .button-group {
     .btn {
       @include media-breakpoint-up('lg') {
         position: relative;
         z-index: 10;
         word-break: normal;
       }
     }
     &:after {
       @include media-breakpoint-up('lg') {
         content: '';
         display: block;
         background-color: $white;
         position: absolute;
         top: 0;
         right: 50%;
         bottom: 0;
         left: 0
       }
     }
   }
  }
}
