#steps {

  $counterRadius: 2.5rem;
  $progressBG: $gray-300;

  .step {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-decoration: none;
    background-color: transparent;
    border: 0;
    .step-counter {
      position: relative;
      z-index: 2;
      flex: 0 0 $counterRadius;
      width: $counterRadius;
      height: $counterRadius;
      border-radius: $counterRadius * 0.5;
      border-style: solid;
      border-width: 1px;
      border-color: $dark;
      color: $dark;
      background-color: $light;
      @include font-size($font-size-base);
      font-weight: $font-weight-semibold;
    }
    .step-label {
      text-align: center;
      color: $dark;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      left: 0;
      width: 50%;
      height: 1px;
      position: absolute;
      top: $counterRadius * 0.5;
      background-color: $progressBG;
    }
    &:after {
      left: auto;
      right: 0;
    }

    &.step-completed {
      .step-counter {
        background-color: $dark;
        color: $white;
      }
      &:before,
      &:after {
        background-color: $dark;
      }
    }
    &.step-active {
      &:before {
        background-color: $dark;
      }
    }
    &.step-disabled {
      .step-counter {
        border-color: $progressBG;
        color: $text-muted;
      }
      .step-label {
        color: $text-muted;
      }
    }

    &.step-completed,
    &.step-active {
      &:hover,
      &:focus {
        .step-counter {
          color: $white;
          background-color: $primary;
          border-color: $primary;
        }
      }
    }

    &:first-child {
      &:before {
        left: auto;
        right: 50%;
        width: 50vw;
      }
    }
    &:last-child {
      &:after {
        left: 50%;
        right: auto;
        width: 50vw;
      }
      &.step-active {
        &:after {
          background-color: $dark;
        }
      }
    }

  }

}
