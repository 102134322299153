.badge {
  letter-spacing: $letter-spacing;
  line-height: 1;
}

.badge-sm {
  @include font-size(12px);
}

.badge-bullet {
  padding: 0;
  display: flex;
  align-items: center;
  span:last-child {
    white-space: initial;
    text-align: left;
    line-height: 1.5;
  }
  span:first-child {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 0.625rem;
    flex: 0 0 0.625rem;
    margin: 0 0.875rem 0 0;
  }
}
