.l-errors {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $body-bg;
  min-height: 100vh;
  ul {
    li {
      a {
        padding: 5px 0;
        color: $dark;
        &:hover,
        &:focus,
        &.active {
          color: $primary;
        }
      }
    }
  }
  .btn-close {
    .icon {
      transform: scaleX(-1);
    }
  }
}
