main#page-blog-detail {
  .author {
    .img-wrapper {
      $thumbSize: 5rem;
      height: $thumbSize;
      width: $thumbSize;
      flex: 0 0 $thumbSize;
      img {
        width: 100%;
      }
    }
  }
  #related-articles {
    .h3 {
      margin-top: 2.5rem;
      @include media-breakpoint-up(md) {
        margin-top: 3.75rem;
      }
      @include media-breakpoint-up(lg) {
        margin-top: 5rem;
      }
    }
  }
  [class*='widget-'].bg-light{
    + #related-articles {
      .h3 {
        margin-top: 0;
      }
    }
  }
  .widget-call-to-action.bg-light {
    + #related-articles {
      .h3 {
        margin-top: 2.5rem;
        @include media-breakpoint-up(md) {
          margin-top: 3.75rem;
        }
        @include media-breakpoint-up(lg) {
          margin-top: 5rem;
        }
      }
      margin-top: -4.375rem;
      @include media-breakpoint-up(md) {
        margin-top: -6.25rem;
      }
      @include media-breakpoint-up(xxl) {
        margin-top: -8.75rem;
      }
    }
  }

}
