@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// Custom
$container-fluid-max-width: 100rem;
$z-index-cookies: 10000;
$letter-spacing: 0.0625rem;

// Color system
$gray-100: #FAF7F2;
$gray-300: #DFDFDF;
$gray-400: #9d9d9d;
$gray-800: #C2A7A7;
$gray-900: #6D6D6D;

$red: #E00020;
$orange: #EA9F37;
$green: #2DBB66;

$orange-900: #E39041;
$blue-100: #88C1DC;
$blue-900: #5488C7;
$red-100: #EC5F65;
$green-900: #2C7B72;
$purple-900: #5b57a2;

$primary: $red;
$secondary: $gray-100;
$light: $gray-100;
$dark: $black;

$info: $white;

$theme-colors: (
		"primary": $primary,
		"secondary": $secondary,
		"success": $success,
		"info": $info,
		"warning": $warning,
		"danger": $red,
		"light": $light,
		"dark": $dark,
    "red": $red,
    "orange": $orange,
    "green": $green,
		"gray-300": $gray-300,
		"gray-400": $gray-400,
		"gray-800": $gray-800,
		"gray-900": $gray-900,
		"orange-900": $orange-900,
		"blue-100": $blue-100,
		"blue-900": $blue-900,
		"red-100": $red-100,
		"green-900": $green-900,
		"purple-900": $purple-900
);

// Options
$enable-negative-margins: true;

// Spacing
$spacer: 0.625rem;
$spacers: (
		0: 0,
		1: $spacer,
		2: $spacer * 2,
		3: $spacer * 3,
		4: $spacer * 4,
		5: $spacer * 5,
		6: $spacer * 6,
		7: $spacer * 7,
		8: $spacer * 8,
		9: $spacer * 9,
		10: $spacer * 10,
		12: $spacer * 12,
		14: $spacer * 14,
);

$position-values: (
  0: 0,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
);

// Body
$body-bg: $gray-100;
$body-color: $black;

// Paragraphs
$paragraph-margin-bottom: 2.5rem;

// Grid columns
$grid-gutter-width: 1.875rem;
$container-padding-x: $grid-gutter-width * 0.625;
$gutters: $spacers;

// Components
$border-color: $black;

$border-radius: 0.25rem;
$border-radius-sm: 0.5rem;
$border-radius-xs: 0.375rem;

$box-shadow: none;
$box-shadow-sm: none;
$box-shadow-lg: none;
$box-shadow-inset: none;

// Typography
$font-family-sans-serif: "poppins", "Helvetica", "Arial", sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 0.9375rem;

$font-weight-medium: 500;
$font-weight-base: $font-weight-medium;

$line-height-base: 1.5;
$line-height-sm: 1.2;
$line-height-md: 1.8;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.67;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.33;
$h4-font-size: $font-size-base * 1.066;
$h5-font-size: $font-size-base * 0.8;
$h6-font-size: $font-size-base * 0.67;

$font-sizes: (
		1: $h1-font-size,
		2: $h2-font-size,
		3: $h3-font-size,
		4: $h4-font-size,
		5: $h5-font-size,
		6: $h6-font-size,
		10: 0.625rem,
		11: 0.6875rem,
		12: 0.75rem,
		13: 0.8125rem,
		14: 0.875rem,
		15: 0.9375rem,
		16: 1rem,
		18: 1.125rem
);

$headings-margin-bottom: $spacer * 4;
$headings-font-family: "Playfair Display", Georgia, Times New Roman, serif;
$headings-font-style: normal;
$headings-font-weight: $font-weight-semibold;
$headings-line-height: 1.2;
$headings-color: $dark;

$display-font-sizes: (
		1: 3.125rem
);

$display-font-family: $font-family-sans-serif;
$display-font-style: normal;
$display-font-weight: $font-weight-bold;
$display-line-height: 1.2;

$small-font-size: 0.75rem;

$hr-opacity: 1;

$text-muted: $gray-400;


// Tables

$table-cell-padding-y:        .625rem;
$table-cell-padding-x:        .625rem;
$table-cell-padding-y-sm:     .3125rem;
$table-cell-padding-x-sm:     .3125rem;

$table-cell-vertical-align:   top;

$table-color:                 $dark;
$table-bg:                    transparent;
$table-accent-bg:             transparent;

$table-th-font-weight:        $font-weight-medium;

$table-striped-color:         $table-color;
$table-striped-bg-factor:     .05;
$table-striped-bg:            rgba($black, $table-striped-bg-factor);

$table-active-color:          $table-color;
$table-active-bg-factor:      .1;
$table-active-bg:             rgba($black, $table-active-bg-factor);

$table-hover-color:           $table-color;
$table-hover-bg-factor:       .075;
$table-hover-bg:              rgba($black, $table-hover-bg-factor);

$table-border-factor:         .1;
$table-border-width:          $border-width;
$table-border-color:          var(--#{$prefix}border-color);

$table-striped-order:         even;
$table-striped-columns-order: even;

$table-group-separator-color: currentcolor;

$table-caption-color:         $text-muted;

$table-bg-scale:              -80%;

// Buttons + inputs
$input-btn-font-family: $font-family-sans-serif;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: 1.5;

$input-btn-focus-box-shadow: none;

// Buttons
$btn-padding-y: 0.875rem;
$btn-padding-x: 1.5rem;
$btn-padding-y-sm: 0.625rem;
$btn-padding-x-sm: 0.9375rem;

$btn-font-size: $font-size-base;
$btn-font-weight: $font-weight-semibold;
$btn-box-shadow: none;
$btn-disabled-opacity: 1;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-line-height: 1.2;

$btn-link-disabled-color: $gray-400;

// Inputs
$input-font-size: $font-size-base;

// Forms
$form-label-margin-bottom: 1.875rem;
$form-label-font-size: 1.125rem;
$form-label-font-weight: $font-weight-semibold;
$form-label-color: $black;

$input-padding-y: 1.125rem;
$input-padding-y-sm: 0.625rem;
$input-padding-x: 1.5rem;

$input-font-weight: $font-weight-medium;

$input-bg: $white;
$input-disabled-color: $gray-900;
$input-disabled-bg: $gray-300;
$input-disabled-border: $gray-300;

$input-color: $dark;
$input-border-color: $gray-300;

$input-focus-border-color: $black;
$input-focus-color: $black;
$input-focus-box-shadow: none;

$input-placeholder-color: $gray-400;
$input-plaintext-color: $black;

$form-check-input-width: 1.625rem;
$form-check-min-height: $form-check-input-width;
$form-check-padding-start: $form-check-input-width + 0.875rem;
$form-check-margin-bottom: $font-size-base;
$form-check-label-color: $black;
$form-check-input-focus-box-shadow: none;

$form-check-input-checked-color: $primary;
$form-check-input-border: solid 1px $input-border-color;
$form-check-input-border-radius: $border-radius;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12'><path fill='#{url-friendly-colour($form-check-input-checked-color)}' d='M12.4263 0.626078L6.20637 7.98589L3.50111 5.21792C2.92299 4.62655 1.96873 4.6097 1.36959 5.18069C0.770842 5.75169 0.75378 6.6942 1.33189 7.28596L4.26293 10.2851L5.53065 11.5823C5.97822 12.04 6.72894 12.0134 7.14199 11.5247L8.3121 10.1405L14.7408 2.53383C15.2741 1.90248 15.1887 0.963892 14.5495 0.436792C13.9103 -0.0899159 12.96 -0.00565823 12.4263 0.625686V0.626078Z'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{url-friendly-colour($form-check-input-checked-color)}'/></svg>");

$form-check-input-checked-bg-color: $input-bg;
$form-check-input-checked-border-color: $input-border-color;

$form-check-inline-margin-end: 1.25rem;

$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-font-weight: $input-font-weight;
$form-select-bg-position: right $form-select-padding-x center;
$form-select-bg-size: 22px 8px;
$form-select-indicator-color: $black;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 8'><path fill='none' stroke='#{url-friendly-colour($form-select-indicator-color)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.4' d='M1 1L11 7L21 1'/></svg>");

$form-select-box-shadow: 0;
$form-select-focus-box-shadow: 0;
$form-select-border-color:        $input-border-color;
$form-select-focus-border-color:  $input-focus-border-color;
$form-floating-height:            add(3.5rem, $input-height-border);
$form-floating-line-height:       1.25;
$form-floating-padding-x:         $input-padding-x;
$form-floating-padding-y:         1rem;
$form-floating-input-padding-t:   1.625rem;
$form-floating-input-padding-b:   .625rem;
$form-floating-label-opacity:     1;
$form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(.25rem);
$form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out;

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top:          0.625rem;
$form-feedback-font-size:           $form-text-font-size;
$form-feedback-font-style:          $form-text-font-style;
$form-feedback-valid-color:         $success;
$form-feedback-invalid-color:       $danger;

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
$form-feedback-icon-valid:          none;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$form-feedback-icon-invalid:        none;
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid
  )
);

// Navs
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 1.5625rem;
$nav-link-font-size: $font-size-base;
$nav-link-font-weight: $font-weight-semibold;

$nav-tabs-border-color:             $black;
$nav-tabs-border-width:             1px;
$nav-tabs-border-radius:            0;
$nav-tabs-link-color:              $black;
$nav-tabs-link-hover-border-color:  $black;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-bg:           $white;
$nav-tabs-link-active-border-color: $black;

// Navbar
$navbar-padding-y: 3.125rem;

$navbar-nav-link-padding-x: 1.5625rem;

$navbar-brand-padding-y: 0;

$navbar-dark-color: $white;

$navbar-light-color: $black;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

// Dropdowns
$dropdown-min-width: 18.75rem;
$dropdown-padding-x: 1.25rem;
$dropdown-padding-y: 0.9375rem;

$dropdown-font-size: $font-size-base;
$dropdown-color: $black;
$dropdown-bg: $white;
$dropdown-border-color: $gray-300;
$dropdown-border-radius: $border-radius-sm;

$dropdown-link-color: $black;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: transparent;

$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: $dropdown-link-hover-bg;

$dropdown-link-disabled-color: $gray-400;

$dropdown-item-padding-x: $input-padding-x;
$dropdown-item-padding-y: 0.625rem;

$dropdown-divider-bg: $dropdown-border-color;

$dropdown-header-padding-x: $input-padding-x;
$dropdown-header-padding-y: 0.625rem;
$dropdown-header-color: $gray-400;

// Pagination
$pagination-padding-y: 0;
$pagination-padding-x: 0;

$pagination-font-size: $font-size-base;

$pagination-color: $black;
$pagination-bg: transparent;
$pagination-border-radius: 0;
$pagination-border-color: transparent;

$pagination-focus-color: $primary;
$pagination-focus-bg: transparent;

$pagination-hover-color: $primary;
$pagination-hover-bg: transparent;
$pagination-hover-border-color: transparent;

$pagination-active-color: $primary;
$pagination-active-bg: transparent;
$pagination-active-border-color: transparent;


// Cards
$card-spacer-y:                     2.5rem;
$card-spacer-x:                     2.5rem;
$card-title-spacer-y:               1.25rem;
$card-border-width:                 $border-width;
$card-border-color:                 $gray-300;
$card-border-radius:                $border-radius;
$card-box-shadow:                   null;
$card-color:                        $dark;
$card-bg:                           $white;

// Accordion
$accordion-padding-y:                     1.25rem;
$accordion-padding-x:                     1.25rem;
$accordion-bg:                            $white;
$accordion-border-radius:                 $border-radius-sm;

$accordion-body-padding-y:                $accordion-padding-y * 2;
$accordion-body-padding-x:                $accordion-padding-x * 2;

$accordion-button-padding-y:              $accordion-padding-y;
$accordion-button-padding-x:              $accordion-padding-x;
$accordion-button-active-bg:              $white;
$accordion-button-active-color:           $dark;

$accordion-button-focus-border-color:     $input-focus-border-color;
$accordion-button-focus-box-shadow:       none;

$accordion-icon-transform:                scaleY(-1);

// Badges
$badge-font-size: .875rem;
$badge-font-weight: $font-weight-semibold;
$badge-color: $white;
$badge-color: $white;
$badge-padding-y: 0.625rem;
$badge-padding-x: 0.625rem;
$badge-border-radius: $border-radius;


// Modals
$modal-dialog-margin:               2.5rem;
$modal-inner-padding:               2.5rem;

$modal-footer-margin-between:       0;

$modal-title-line-height:           $line-height-base;

$modal-content-bg:                  $white;
$modal-content-border-color:        $gray-400;
$modal-content-border-radius:       $border-radius-sm;
$modal-content-box-shadow-xs:       none;
$modal-content-box-shadow-sm-up:    none;

$modal-backdrop-bg:                 $light;
$modal-backdrop-opacity:            0.9;

$modal-header-border-color:         var(--#{$prefix}border-color);
$modal-header-border-width:         $modal-content-border-width;
$modal-header-padding-y:            $modal-inner-padding;
$modal-header-padding-x:            $modal-inner-padding;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-footer-bg:                   null;
$modal-footer-border-color:         $modal-header-border-color;
$modal-footer-border-width:         $modal-header-border-width;

$modal-sm:                          300px;
$modal-md:                          500px;
$modal-lg:                          800px;
$modal-xl:                          1140px;


// Breadcrumbs
$breadcrumb-font-size: 0.75rem;
$breadcrumb-padding-x: 0rem;
$breadcrumb-item-padding-x: 0.625rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $gray-900;
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12.8' height='12.8' viewBox='0 0 12.8 12.8'><path fill='none' stroke-width='1.2' stroke='#{url-friendly-colour($breadcrumb-divider-color)}' d='M0,6.4h12'/><path fill='none' stroke-width='1.2' stroke='#{url-friendly-colour($breadcrumb-divider-color)}' d='M6,0.4l6,6l-6,6'/></svg>");
$breadcrumb-active-color: $black;
$breadcrumb-border-radius: 0;

// Close
$btn-close-width: 1.25em;
$btn-close-height: 1.25em;
$btn-close-padding-x: 0;
$btn-close-padding-y: 0;
$btn-close-color: $black;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;
$btn-close-disabled-opacity: 1;


// Offcanvas
$offcanvas-padding-y: $modal-inner-padding;
$offcanvas-padding-x: $modal-inner-padding;
$offcanvas-horizontal-width: 300px;
$offcanvas-vertical-height: 30vh;
$offcanvas-transition-duration: .3s;
$offcanvas-border-color: $modal-content-border-color;
$offcanvas-border-width: $modal-content-border-width;
$offcanvas-title-line-height: $modal-title-line-height;
$offcanvas-bg-color: $light;
$offcanvas-color: $dark;
$offcanvas-box-shadow: $modal-content-box-shadow-xs;
$offcanvas-backdrop-bg: $dark;
$offcanvas-backdrop-opacity: 0.3;
