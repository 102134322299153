#menuOverlay {
  .modal-dialog {
    max-width: unset;
    margin: 0;
    @include media-breakpoint-up(md) {
      height: 100vh;
      .modal-content,
      .row,
      [class*='col'] {
        height: 100%;
      }
    }
  }
  .nav-item {
    a.h3 {
      color: $gray-900;
      &:hover,
      &:focus,
      &.active {
        color: $black;
      }
      @include media-breakpoint-down(lg) {
        @include font-size($font-size-base);
      }
    }
    a.active,
    a:hover,
    a:focus {
      color: $navbar-light-active-color;
    }
  }
  .nav-social {
    bottom: 8vh;
    @media screen and (max-height: 600px) {
      display: none !important;
    }
  }
}
