main#page-home {

  $headerHeight: 11.625rem;
  $offset: 10rem;
  $bannerBP: 62.5rem;

  #banner {
    .caption {
      padding: $headerHeight 0;
      @include media-breakpoint-up(md) {
        @media screen and (max-height: $bannerBP) {
          min-height: 100vh;
          display: flex;
          align-items: center;
        }
      }
      @media screen and (min-height: $bannerBP) {
        padding: calc($headerHeight + 10vh) 0;
      }
    }

    @include media-breakpoint-up(md) {
      min-height: 100vh;
    }
    @media screen and (min-height: $bannerBP) {
      min-height: unset;
    }
  }

  #quick-links {
    position: relative;

    ul {
      li {
        a {
          &:not(.btn) {
            display: block;
            padding: 5px 0;
            color: $navbar-light-color;
            text-decoration: none;

            &.active,
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    [class*='col']:last-child {
      border-top: solid 1px $gray-300;
      @include media-breakpoint-up(lg) {
        border-left: solid 1px $gray-300;
        border-top: 0;
      }
    }
  }

  #education-categories {
    .categories-row {
      @include media-breakpoint-only(xs) {
        margin-right: -5px;
        margin-left: -5px;
        [class*='col'] {
          padding: 0 5px;
        }
      }
    }
  }

  #education-spotlight {
    .nav-tabs {
      .nav-item {
        .nav-link {
          &,
          &.active,
          &:hover,
          &:focus {
            text-align: left;
            color: $black;
          }
        }
      }

      @include media-breakpoint-up(md) {
        border: 0;
        .nav-item {
          .nav-link {
            $badgeSize: 0.5rem;
            $badgeOffset: 1rem;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            border: 0;
            padding: 1rem 0;
            border-bottom: solid 1px $nav-tabs-border-color;
            transition: $transition-base;

            .badge {
              display: inline-block;
              width: $badgeSize;
              height: $badgeSize;
              border-radius: $badgeSize * 0.5;
              padding: 0;
              position: absolute;
              top: 50%;
              left: -$badgeSize;
              transform: translate(0, -50%);
              opacity: 0;
            }

            &:hover,
            &:focus,
            &.active {
              padding-left: calc($badgeSize + $badgeOffset);

              .badge {
                left: 0;
                opacity: 1;
              }
            }
          }

          &:first-child {
            .nav-link {
              border-top: solid 1px $nav-tabs-border-color;
            }
          }
        }
      }
      @include media-breakpoint-down(md) {
        border-top: solid 1px $nav-tabs-border-color;
        border-right: 0;
        border-bottom: 0;
        border-left: solid 1px $nav-tabs-border-color;
        .nav-item {
          flex: 1 1 auto;

          .nav-link {
            &,
            &:hover,
            &:focus,
            &.active {
              width: 100%;
              border-top: 0;
              border-right: solid 1px $nav-tabs-border-color;
              border-bottom: solid 1px $nav-tabs-border-color;
              border-left: 0;
              text-align: center;
            }
          }
        }
      }
    }

    .tab-content {
      @include media-breakpoint-down(md) {
        background-color: $light;
        border: solid 1px $nav-tabs-border-color;
        border-top: 0;
      }
    }
  }

}
