.c-card-course {
  border-bottom: 0;
  color: $card-color;
  ul {
    li {
      line-height: 1.8;
    }
  }
  .card-body + div > .card-body {
    border-top: solid 1px $card-border-color;
  }
  .stripe {
    height: 2px;
    border-radius: 0 0 2px 2px;
  }
}
