#educationProgramOverlay {

  $dropdown-padding-x: 0.625rem;
  $dropdown-padding-y: 0.625rem * 2;

  .nav-link {
    background-color: transparent;
    color: $gray-400;
    width: 100%;
    text-align: left;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $dark;
    }
    @include media-breakpoint-up(xl) {
      padding: $dropdown-padding-x $dropdown-padding-y * 2 $dropdown-padding-x $dropdown-padding-y;
    }
    @include media-breakpoint-down(xl) {
      padding: $dropdown-padding-x $dropdown-padding-y;
    }
  }
  .nav-pills {
    @include media-breakpoint-up(xl) {
      height: 100%;
    }
    @include media-breakpoint-down(xl) {
      display: flex;
      flex-wrap: wrap;
      border-bottom: solid $nav-tabs-border-width $nav-tabs-border-color;
    }
  }
  .dropdown {
    @include media-breakpoint-down(lg) {
      position: sticky;
      top: 0;
      background-color: $light;
    }
  }

  .tab-content {
    @include media-breakpoint-up(xl) {
      border-left: solid $nav-tabs-border-width $nav-tabs-border-color;
    }
  }

  @include media-breakpoint-up(lg) {
    .dropdown-menu {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      position: relative;
      top: auto !important;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      .dropdown-item {
        border: 0;
        &.active,
        &:focus,
        &:active {
          color: $nav-tabs-link-active-color;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .table {
      td {
        display: flex;
        background-color: $white;
        padding: $table-cell-padding-x * 2;
        span {
          flex: 0 0 100px;
        }
        + td {
          padding-top: 0;
        }
      }
    }
  }

  .th-date,
  .th-time,
  .td-date,
  .td-time {
    @include media-breakpoint-up(md) {
      min-width: 140px;
    }
  }
  .td-session {
    @include media-breakpoint-up(md) {
      min-width: 260px;
    }
  }

}
