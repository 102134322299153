.c-card-contact {
  .card-img {
    width: 50%;
    aspect-ratio: 1/1;
    @include media-breakpoint-only(sm) {
      width: 40%;
    }
  }
  p {
    margin: 0;
    a {
      color: $gray-400;
      &:hover,
      &:focus {
        color: $primary;
      }
    }
    + p {
      margin: 0.375rem 0 0 0;
    }
  }
}
