.l-site-nav {

  $nav-breakpoint: 'lg';
  $offcanvas-width: 18.75rem;
  $offcanvas-top: 0; // could also be navbar height
  $offcanvas-bg-color: $black;
  $offcanvas-y: 2.5rem;
  $offcanvas-nav-link-padding-x: 1.25rem;
  $offcanvas-nav-link-padding-y: 1.25rem;

  position: relative;
  background-color: $white;
  flex-direction: column;

  .navbar-brand {
   img {
     height: 3.438rem;
     max-width: 50vw;
   }
  }

  .nav-link {
    display: flex;
    align-items: center;
    letter-spacing: $letter-spacing;
    line-height: $line-height-base;
    .icon {
      position: relative;
      stroke-width: 1.4;
      stroke-linecap: round;
      &:last-child {
        margin-left: 14px;
      }
      @include media-breakpoint-up($nav-breakpoint) {
        top: -1px;
      }
    }
    &.navbar-dropdown__main-link {
      @include media-breakpoint-down(lg) {
        display: flex;
        justify-content: space-between;
      }
    }
    @include media-breakpoint-down($nav-breakpoint) {
      color: $navbar-light-color;
    }
  }
  .nav-icon-link {
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    padding: 0.625rem;
    height: 100%;
    .icon {
      stroke: $navbar-light-color;
      stroke-width: 1.4;
      fill: transparent;
    }
    @include media-breakpoint-up($nav-breakpoint) {
      padding: 0 0.9375rem;

    }
    &:hover,
    &.active,
    &:focus {
      .icon {
          stroke: $navbar-light-active-color;
      }
    }
  }

  button.nav-icon-link:not(.hamburger) {
    @include media-breakpoint-down(sm) {
      background-color: $primary;
      border-radius: 100px;
      .icon {
        stroke: $white;
      }
    }
  }

  .nav-icon-link[data-target] {
    padding: 0 $nav-link-padding-x;
  }

  .badge {
    top: -6px;
    right: 0;
    background-color: $primary;
    font-size: 0.9rem;
    height: 1.25rem;
    min-width: 1.25rem;
    border-radius: 1.25rem;
    text-align: center;
    padding: 1px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-medium;
    @include media-breakpoint-up($nav-breakpoint) {
      top: 12px;
    }
    @include media-breakpoint-down(sm) {
      right: auto;
      left: calc(50% + 4px);
      top: 14px;
    }
  }

  .nav-secondary {
    $gutter-x: 0.875rem;
    li {
      display: flex;
      align-items: center;
      a.nav-link {
        color: $gray-400;
        padding: 0 $gutter-x;
        &.active,
        &:hover {
          color: $primary;
        }
      }
      &:not(:last-child) {
        &:after {
          content: '';
          display: inline-block;
          width: 3px;
          height: 3px;
          background-color: $gray-400;
          margin: 0;
          border-radius: 100px;
        }
      }
    }
  }
  .nav-primary {
    @include media-breakpoint-down($nav-breakpoint) {
      border-bottom: solid 1px $dark;
    }
   @include media-breakpoint-up($nav-breakpoint) {
     margin-top: 1rem;
   }

  }
  .nav-icons {
    @include media-breakpoint-up($nav-breakpoint) {
      padding-left: 15px;
      border-left: solid 1px $dark;
      margin-left: 15px;
    }
    @include media-breakpoint-up(xl) {
      margin-left: $nav-link-padding-x;
      padding-left: $nav-link-padding-x + 0.625rem;
    }
  }
  .nav-meta {
    li {
      a.nav-link {
        padding: 0.625rem 1.25rem;
        color: $navbar-light-color;
        &:hover,
        &.active {
          color: $navbar-light-active-color;
        }
      }
    }
  }
  .nav-cart {
    border-top: solid 1px $navbar-light-color;
    a {
      flex: 1;
      justify-content: center;
      padding: 1.25rem 1rem;
      + a {
        border-left: solid 1px $navbar-light-color;
      }
    }
  }

  .offcanvas {
    border-left: 0;

    .btn-close {
      @include media-breakpoint-up($nav-breakpoint) {
        display: none;
      }
    }
    .nav-link {
      @include media-breakpoint-down($nav-breakpoint) {
        padding: $offcanvas-nav-link-padding-y $offcanvas-nav-link-padding-x;
        &.active {
          color: $navbar-light-active-color;
        }
      }
    }

    @include media-breakpoint-up($nav-breakpoint) {
      position: static;
      visibility: visible !important;
      background: none;
      border: 0;
      transform: none;
      width: auto;
      margin-left: auto;
    }

  }

  &.navbar-expand-lg {
    @include media-breakpoint-up($nav-breakpoint) {
      .navbar-nav {
        .nav-link {
          padding: 0 15px;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      .navbar-nav {
        .nav-link {
          padding: 0 $nav-link-padding-x;
        }
      }
    }
  }
  &.navbar-dark {
    background-color: transparent;
    z-index: $zindex-offcanvas - 100;
    .nav-secondary {
      li {
        a.nav-link {
          color: $navbar-dark-color;
        }
        &:not(:last-child):after {
          background-color: $navbar-dark-color;
        }
      }
    }
    .nav-icons {
      border-left-color: $navbar-dark-color;
    }
    .nav-icon-link {
      .icon {
        stroke: $navbar-dark-color;
      }
    }
    .offcanvas {
      .nav-icon-link {
        .icon {
          stroke: $navbar-light-color;
        }
      }
    }
  }
}

