main#page-course-registration {

  table {
    tr {
      td {
        vertical-align: middle;
        &:first-child {
          @include media-breakpoint-up(sm) {
            width: 200px;
          }
        }
      }
      &:first-child td {
        padding-top: 0;
      }
      &:last-child td {
        padding-bottom: 0;
      }
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        td + td {
          padding-top: 0;
        }
        + tr {
          margin-top: 5px;
        }
      }
    }
  }

  aside {
    @include media-breakpoint-up(md) {
      top: 2.5rem;
    }
  }

  .btn {
    &.btn-link {
      flex: 0 0 auto;
      &:hover,
      &:focus {
        color: $primary !important;
      }
    }
  }

  .list-group {
    .list-group-item {
      padding: 7.5px 0;
      .icon {
        flex: 0 0 14px;
        margin: 0 14px 0 0;
      }
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .form-section-top-sm {
    padding-top: 2.5rem;
    @include media-breakpoint-up(md) {
      padding-top: 3.75rem;
    }
  }

  .custom-radio {
    $checkSize: 1.375rem;
    label {
      border: solid 1px $dark;
    }
    .checkmark {
      flex: 0 0 0;
      transition: $transition-base;
      overflow: hidden;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $checkSize;
        height: $checkSize;
        border-radius: $checkSize * 0.5;
      }
    }
    input:checked ~ label .checkmark {
      flex: 0 0 calc($checkSize + 1.25rem);
    }
    &:hover {
      input:not(:checked) ~ label {
        border: solid 1px $primary;
      }
    }

  }

  .course-participants-child {
    margin-top: 1.25rem;
    .accordion-header {
      display: flex;
    }
    .accordion-button {
      background-color: transparent;
      &:after {
        display: none;
      }
    }
    .btn-remove {
      $radius: 1.375rem;
      display: flex;
      align-items: center;
      flex: 1 0 auto;
      border: 0;
      border-radius: 0;
      padding: $accordion-padding-y * 0.5 $accordion-padding-x;
      color: $gray-400;
      text-transform: uppercase;
      letter-spacing: $letter-spacing;
      font-weight: $font-weight-semibold;
      background-color: transparent;
      @include font-size(11px);
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 $radius;
        width: $radius;
        height: $radius;
        border-radius: $radius * 0.5;
        background-color: $danger;
        margin: 0 0 0 0.875rem;
        .icon {
          stroke-width: 3.5;
        }
      }
    }
    &:first-of-type {
      margin-top: 0;
      .btn-remove {
        display: none;
      }
    }
  }

  .accordion-item {
    &:first-of-type {
      border-radius: $accordion-inner-border-radius;
    }
    &:not(:first-of-type) {
      border-radius: $accordion-inner-border-radius;
      border-top: solid $accordion-border-width $accordion-border-color;
    }
  }

  .participant-summary-item {
    margin: 0;
    + .participant-summary-item {
      margin: 1.25rem 0 0 0;
    }
  }

  .summary {
    @include media-breakpoint-down('lg') {
      background-color: darken($gray-100, 5%);
    }
    > div {
      @include media-breakpoint-up('lg') {
        background-color: $white;
      }
    }
  }

  #course-summary-date {
    .card {
      span + span {
        margin-top: 4px;
      }
    }
  }

}
