@each $name, $value in $theme-colors {
  .fill-#{$name} {
    fill: $value;
  }

  .stroke-#{$name} {
    stroke: $value;
    fill: none;
  }


}

.btn-outline-dark {
    &:hover,
    &:focus,
    &:active,
    &.active {
        background-color: $primary;
        border-color: $primary;
        color: $white;
    }
}


.fill-white {
  fill: $white;
}

.fill-black {
  fill: $black;
}

.stroke-white {
  stroke: $white;
  fill: none;
}

.stroke-black {
  stroke: $black;
  fill: none;
}

.pattern-black {
  height: 30px;
  width: 100%;
  background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='20' patternTransform='scale(1) rotate(45)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0, 0%, 100%, 1)'/><path d='M0 10h20z'   stroke-width='2.5' stroke='hsla(259, 0%, 0%, 1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(-40,-160)' fill='url(%23a)'/></svg>")
}
