.c-card-location {
  address > span,
  p > span,
  p > a {
    color: $gray-400;
    @include font-size(14px);
    letter-spacing: $letter-spacing;
    font-weight: $font-weight-semibold;
    display: block;
    text-decoration: none;
  }
  p {
    margin: 0.3125rem 0 0 0;
  }
}
