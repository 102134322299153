.fade-out-siblings {
  pointer-events: none;

  > * {
    cursor: pointer;
    pointer-events: auto;
    transition: $transition-base;
  }

  &:hover > * {
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }

  }

}
