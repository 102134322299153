.c-nav-dropdown {

  $nav-breakpoint: 'lg';

  position: relative;

  @include media-breakpoint-down($nav-breakpoint) {
    width: 100%;
    border-bottom: solid 1px $dark;
  }

  &:not(.open) {
    @include media-breakpoint-up($nav-breakpoint) {
      .nav-dropdown__menu,
      .nav-dropdown__link {
        transition: $transition-base;
      }

      .nav-dropdown__menu {
        transform: translateY(-15px) translateX(-50%) scale(0);
      }

      .nav-dropdown__link {
        opacity: 0;
      }
    }
  }

  &.open .navbar-dropdown__main-link.active::before {
    @include media-breakpoint-up($nav-breakpoint) {
      transform: scale(0);
      transition: $transition-base;
    }
  }

  .nav-dropdown__menu {

    .icon {
      display: none;
      @include media-breakpoint-up($nav-breakpoint) {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        fill: $dropdown-border-color;
      }
    }

    @include media-breakpoint-up($nav-breakpoint) {
      display: block;
      position: absolute;
      left: 50%;
      top: calc(100% + 30px);
      width: 270px;
      border-radius: $border-radius-sm;
      transform: translateX(-50%);
      transform-origin: 50% 0;
      will-change: transform;
      z-index: 5000;
      transition: $transition-base;
      padding: 0;
      background-color: $dropdown-bg;
      border: solid 1px $dropdown-border-color;
      ul {
        + ul {
          border-top: solid 1px $gray-300;

          li {
            a.nav-dropdown__link:not(:hover):not(.active) {
              color: $gray-400;
              font-weight: $font-weight-semibold;
              letter-spacing: $letter-spacing;
              text-decoration: underline;
            }
          }
        }
      }
    }

    @include media-breakpoint-down($nav-breakpoint) {
      margin: 0;
      background-color: $white;
      ul {
        padding: 10px 0;
      }
    }

  }

  .nav-dropdown__link {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-semibold;
    transition: $transition-base;
    text-align: center;
    display: block;
    padding: 10px 30px;
    @include font-size($font-size-base);
    letter-spacing: $letter-spacing;
    text-decoration: none;

    &:not(:hover):not(.active) {
      color: $dark;
    }

    &:hover:not(.active) {
      color: $primary;
    }

    &.active {
      @include media-breakpoint-down($nav-breakpoint) {
        color: $primary;
      }
      @include media-breakpoint-up($nav-breakpoint) {
        color: $primary;
      }
    }

    @include media-breakpoint-down($nav-breakpoint) {
      text-align: left;
      padding: 12px 20px;
    }
  }

  .navbar-dropdown__main-link {
    @include media-breakpoint-down($nav-breakpoint) {

      position: relative;
      padding: 20px;
      &[aria-expanded="true"] {
        .icon {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      &[aria-expanded="false"] + .nav-dropdown__menu {
        display: none;
      }

      &.active,
      &:hover,
      &:focus {
        color: $dark;
      }

    }
  }

  @include media-breakpoint-up($nav-breakpoint) {
      .nav-dropdown__menu.dropdown-dark {
        border-color: $navbar-dark-color;
        .icon {
          display: none;
          @include media-breakpoint-up($nav-breakpoint) {
            display: block;
            fill: $navbar-dark-color;
          }
        }
      }
    }

}
