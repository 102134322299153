textarea.form-control {
  min-height: 10rem;
}

.input-group {
  .btn {
    .icon {
      stroke-width: 1.4;
    }
    &:hover,
    &:focus {
      .icon {
        stroke: $primary;
      }
    }
  }
}

.form-check {
  label {
    a {
      color: $form-check-label-color;
      font-weight: $form-label-font-weight;
    }
  }
  .form-check-input[type='checkbox']{
    background-size: 14px;
  }
}

.form-check-group {
  .form-check:last-child {
    margin-bottom: 0;
  }
}

.form-floating {
  > label {
    letter-spacing: $letter-spacing;
    color: $input-placeholder-color;
  }
  .form-select,
  .form-control:not(:placeholder-shown),
  .form-control:focus,
  .form-control:active {
    & ~ label {
      text-transform: uppercase;
      font-weight: $font-weight-semibold;
      @include font-size(10px);
      padding-top: 1.3rem;
    }
  }
}

.validation-feedback.is-invalid {
  input:not(:valid),
  textarea:not(:valid) {
    border-color: $danger;
  }
  .invalid-feedback {
    display: block;
  }
}

.was-validated {

  .form-check-input,
  .form-select,
  .form-control {
    &:valid,
    &.valid {
      border-color: $input-border-color;
      box-shadow: none;
      &:focus {
        border-color: $input-border-color;
        box-shadow: none;
      }
      &:checked {
        background-color: $form-check-input-bg;
      }
      & ~ .form-check-label {
        color: $form-label-color;
      }
    }
    &:invalid,
    &.is-invalid {
      border-color: $form-feedback-invalid-color;
      box-shadow: none;
      &:focus {
        border-color: $form-feedback-invalid-color;
        box-shadow: none;
      }
    }
  }
}

.valid-feedback,
.invalid-feedback {
  flex: 1 1 100%;
  font-weight: $font-weight-medium;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type='radio'][data-bs-toggle="collapse"]{
    &:checked,
    &:checked + label {
      pointer-events: none;
    }
}

.note {
  margin: 0.625rem 0 0 0;
  font-weight: $font-weight-medium;
  @include font-size(13px);
  letter-spacing: $letter-spacing;
}
