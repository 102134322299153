.l-filter-bar {

  $mobileBreakpoint: 'md';
  $filter-border-color: $gray-300;
  $filter-bg-color: $light;
  $filter-color: $dark;
  $filter-active-color: $primary;

  .filter-item {
    .badge {
      margin: 0 0 0 14px;
      display: none;
      justify-content: center;
      align-items: center;
      background: transparent;
      color: $white;
      min-width: 24px;
      height: 24px;
      text-align: center;
      font-weight: $badge-font-weight;
      padding: 0;
      border-radius: 12px;
      border: solid 1px $white;
      line-height: 1;
      font-size: $badge-font-size;
      @include media-breakpoint-down($mobileBreakpoint) {
        background-color: $filter-active-color;
        border-color: $filter-active-color;
      }
    }
    &[data-multiselect] {
      .dropdown-menu {
        .dropdown-item {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
          &:hover,
          &:focus,
          &.active {
            color: $dropdown-link-active-color;
            background: $dropdown-link-active-bg escape-svg(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 12 8.6' style='enable-background:new 0 0 12 8.6;' xml:space='preserve'%3E%3Cpath d='M0.8,4.3l3.4,3.5l7-7' fill='none' stroke='#{$dropdown-link-active-color}' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")) no-repeat right 24px center;
            background-size: 12px 8px;
          }
        }
      }
    }
    &.active {
      .badge {
        display: flex;
      }
      .btn-filter {
        background-color: $filter-active-color;
        border-color: $filter-active-color;
        color: $white;
        .icon {
          stroke: $white;
        }
        @include media-breakpoint-down($mobileBreakpoint) {
          background-color: transparent;
          color: $filter-color;
          .icon {
            stroke: $filter-color;
          }
        }
      }
    }
  }

  .btn-filter {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    height: 100%;
    min-height: 54px;
    padding: ($btn-padding-y - 0.25rem) $btn-padding-x;
    &.show {
      background-color: $filter-active-color;
      border-color: $filter-active-color;
      color: $white;
      .icon {
        stroke: $white;
      }
    }
    &[data-bs-display="static"] {
      + .dropdown-menu {
        top: calc(100% + 10px) !important;
      }
    }
  }

  .dropdown-menu {
    border: solid 1px $dropdown-border-color;
    max-height: 30vh;
    overflow-y: auto;
    top: 10px !important;
  }

  .offcanvas-header {
    padding-right: $btn-padding-x;
    padding-left: $btn-padding-x;
    border-bottom: solid 1px $filter-border-color;
  }

  @include media-breakpoint-down(md) {
   .container-fluid {
     padding: 0;
   }
  }

  &[class*='offcanvas-'] {
    .filter-item {
      @include media-breakpoint-down($mobileBreakpoint) {
        border-bottom: solid 1px $filter-border-color;
      }
    }
    .btn-filter {
      @include media-breakpoint-down($mobileBreakpoint) {
        border: 0;
        justify-content: space-between;
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: $filter-bg-color;
          color: $filter-color;
          .icon {
            stroke: $filter-color;
          }
        }
        &.show {
          color: $filter-active-color;
          .icon {
            stroke: $filter-active-color;
          }
        }
      }
    }
    .dropdown-menu {
      @include media-breakpoint-down($mobileBreakpoint) {
        position: relative !important;
        transform: none !important;
        inset: unset !important;
        border-radius: 0;
        border: 0;
      }
    }
  }

}
