main#page-course-print {

  @media screen {

    h2.h4 {
      color: $primary;
    }
    @include media-breakpoint-down(lg) {
      .table {
        td {
          display: flex;
          background-color: $white;
          padding: $table-cell-padding-x * 2;
          span {
            flex: 0 0 100px;
          }
          + td {
            padding-top: 0;
          }
        }
      }
    }

  }

  @media print {

    * {
      font-family: Helvetica, Arial, sans-serif;
      color: black;
    }

    h2.h4 {
      color: black;
    }

    table.table-striped {
      > tbody > tr:nth-of-type(even) > * {
        --bs-table-accent-bg: transparent;
      }
      > tbody > tr {
        border: solid 1px black;
      }
      > thead > tr > th:first-child,
      > tbody > tr > td:first-child {
        width: 120px;
      }
    }

    .container {
      max-width: unset;
      width: 100%;
    }

  }
}
