main#page-course-detail {

  .form,
  form {
    .input-group {
      border: solid 1px $input-border-color;
      background-color: $input-bg;
      padding: 7px 7px 7px 0;
    }

    .dropdown-toggle,
    .form-select {
      border: 0;
      background-color: transparent;
      padding: $form-select-padding-y-sm $form-select-padding-x;
      color: $input-color;
      letter-spacing: $letter-spacing;
      font-weight: $font-weight-semibold;
    }

    .dropdown-item {
      padding: 0;

      span {
        padding: $dropdown-padding-x $dropdown-padding-y;

        + span {
          color: $text-muted;
          @include font-size(11px);
        }
      }
    }

    .dropdown-menu {
      width: 100%;
      @include media-breakpoint-down(md) {
        width: calc(100vw - 60px);
      }
      @include media-breakpoint-down(sm) {
        width: calc(100vw - 40px);
      }
    }
  }

  .cutoff {
    height: auto !important;
    transition: none !important;

    &:not(.collapsing) {
      &:not(.show) {
        position: relative;
        max-height: 9rem;
        overflow: hidden;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
      }
    }
  }

  .btn-collapse[aria-expanded="true"] {
    display: none !important;
  }

  .button-group {
    gap: 0.9375rem;
  }

  .card {


    .card-title {
      @include media-breakpoint-up(md) {
        &:hover,
        &:focus,
        &:active {
          cursor: default;
        }
      }
      @include media-breakpoint-down(md) {
        .icon {
          position: relative;
          top: -1px;
          margin: 0 0 0 0.875rem;
          stroke-width: 1.4;
        }
        &[aria-expanded='true'] {
          .icon {
            transform: scaleY(-1);
          }
        }
      }
    }
  }

  #phase-selection {
    .accordion-item {

      border-radius: $input-border-radius;
      overflow: hidden;
      border: solid 1px $dark;

      ul {

        display: flex;
        align-items: center;
        flex-wrap: wrap;

        li {

          @include font-size(12px);
          letter-spacing: 1px;
          font-weight: 600;

          &:not(:last-child) {
            display: flex;
            align-items: center;
            margin-right: 5px;
            &:after {
              content: '';
              display: block;
              width: 1.5px;
              height: 10px;
              background-color: $dark;
              margin-left: 5px;
            }
          }

        }
      }

      .btn.btn-link {
        padding: 15px;
        @include media-breakpoint-up(lg) {
          padding: 15px 5px 0;
        }
      }

      .btn:not(.btn-link) {
        @include media-breakpoint-up(lg) {
          width: 100%;
        }
      }

      .accordion-button {
        .btn.btn-link {
          flex: 1 0 auto;
        }
        .svg-wrapper {
          position: relative;
          background-color: transparent;
          border: solid 1px $dark;
          width: 24px;
          height: 24px;
          border-radius: 12px;
          display: none;
          flex: 0 0 24px;
          .icon {
            @extend %centerer;
          }
        }
        &[aria-expanded='true'] {
          justify-content: space-between;
          .btn.btn-link {
            display: none;
          }
          .svg-wrapper {
            display: block;
          }
        }
      }

      .accordion-body {
        border-top-color: $gray-200;
      }

      + .accordion-item {
        margin-top: 10px;
      }

      &.disabled {
        border-color: $gray-400;
        background-color: transparent;
        .accordion-button {
          background-color: transparent;
          .btn-link {
            background-color: transparent;
            color: $gray-400;
          }
        }
      }

    }
    .accordion-button[aria-expanded='true'] {
      pointer-events: none;
    }
  }

  #course-registration {
    img {
      height: 40px;
    }
  }

  div[id*='card-course-'] {

    &:first-child {
      padding-top: 0 !important;
      border-top: 0 !important;
    }

    .dropdown-toggle {
      &:hover,
      &:focus {
        color: $primary;
        .icon {
          stroke: $primary;
        }
      }
    }

    div:not(.accordion-collapse) {
      &.collapsing,
      &.collapse.show,
      &.collapse {
        @include media-breakpoint-up(md) {
          display: block !important;
          height: auto !important;
        }
      }
    }

  }

  #course-info-nav {
    ul {
      margin: 0 -0.9375rem;

      li {
        a {
          padding-right: 0.9375rem;
          padding-left: 0.9375rem;
        }
      }
    }
  }

  #card-course-program {
    .dropdown-item[aria-expanded='true'] {
      pointer-events: none;
      color: $primary;
    }
  }

  #education-program {
    border: solid 1px $light;

    .education-program-row {
      background-color: $light;

      h3 + p {
        margin: 0.9375rem 0 0 0;
      }

      &:nth-child(even) {
        background-color: $white;
      }
    }
  }

}
