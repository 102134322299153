#searchOverlay {
  ul:not(.js-autocomplete-results-list) {
    li {
      a {
        padding: 5px 0;
        color: $dark;
        &:hover,
        &:focus,
        &.active {
          color: $primary;
        }
      }
    }
  }
  .modal-dialog {
    max-width: 50rem;
  }
}
