.accordion-item {
  .accordion-button {
    font-weight: $font-weight-semibold;
    letter-spacing: $letter-spacing;
    box-shadow: none;
    .icon {
      stroke-width: 1.7;
      transform: none;
      transition: $transition-base;
      &:first-child {
        margin-right: $accordion-padding-x;
      }
    }
    &:after {
      display: none;
    }
    &[aria-expanded='true'] {
      .icon {
        transform: $accordion-icon-transform;
      }
    }
  }
  .accordion-body {
    border-top: solid $accordion-border-width $accordion-border-color;
  }
}
