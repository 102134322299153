.scale-up-siblings {

  .scale-up-child {
    transform: translate(0, 0);
    transition: $transition-base;
    &:hover,
    &:focus {
      transform: translate(0,-10px);
    }
  }

}
