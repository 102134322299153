.section-mt-sm {
  margin-top: 2.5rem;
  @include media-breakpoint-up(md) {
    margin-top: 2.5rem;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 3.75rem;
  }
}
.section-mt-md {
  margin-top: 2.5rem;
  @include media-breakpoint-up(md) {
    margin-top: 3.75rem;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 5rem;
  }
}
.section-mb-md {
  margin-bottom: 2.5rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 3.75rem;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 5rem;
  }
}
.section-mt-lg {
  margin-top: 2.5rem;
  @include media-breakpoint-up(md) {
    margin-top: 3.75rem;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 6.25rem;
  }
}

.section-pt-md {
  padding-top: 2.5rem;
  @include media-breakpoint-up(md) {
    padding-top: 3.75rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 5rem;
  }
}
.section-pb-md {
  padding-bottom: 2.5rem;
  @include media-breakpoint-up(md) {
    padding-bottom: 3.75rem;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 5rem;
  }
}
.section-pt-xl {
	padding-top: 4.375rem;
	@include media-breakpoint-up(md) {
		padding-top: 6.25rem;
	}
	@include media-breakpoint-up(xxl) {
		padding-top: 8.75rem;
	}
}
.section-pb-xl {
	padding-bottom: 4.375rem;
	@include media-breakpoint-up(md) {
		padding-bottom: 6.25rem;
	}
	@include media-breakpoint-up(xxl) {
		padding-bottom: 8.75rem;
	}
}

.image-pattern {
	svg {
		position: absolute;
		width: calc(100% - 20px);
		height: calc(100% - 20px);
	}
	img {
		position: relative;
		z-index: 2;
	}
}

.flip-x {
  transform: scaleX(-1);
}

.gap-1half {
  gap: 0.9375rem;
}

.mt-1half {
  margin-top: 0.9375rem;
}
