.flatpickr-calendar {
  box-shadow: none;
  border: solid 1px $dark;
  border-radius: 10px;
  .flatpickr-months {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    .flatpickr-prev-month,
    .flatpickr-month,
    .flatpickr-next-month {
      display: flex;
      align-items: center;
      height: 40px;
      background-color: $dark;
      svg {
        fill: $white;
        stroke: $white;
        stroke-width: 2;
      }
      &.flatpickr-disabled {
        svg {
          fill: $gray-400;
          stroke: $gray-400;
        }
        &,
        &:hover,
        &:focus {
          pointer-events: none;
        }
      }
    }

    .flatpickr-prev-month {
      border-radius: 10px 0 0 0;
    }
    .flatpickr-month {
      color: $white;
      fill: $white;
      .flatpickr-current-month {
        padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 100%;
        .flatpickr-monthDropdown-months {
          .flatpickr-monthDropdown-month {
            color: $dark;
          }
        }
      }
    }
    .flatpickr-next-month {
      border-radius: 0 10px 0 0;
    }
  }
  .flatpickr-weekdays {
    background-color: $gray-100;
    height: 40px;
    .flatpickr-weekday {
      color: $dark;
      text-transform: uppercase;
      font-size: 80%;
    }
  }
  .flatpickr-day {
    &:hover,
    &:focus {
      color: $primary;
      background-color: transparent;
      border-color: transparent;
    }
    &.today {
      &,
      &:hover,
      &:focus {
        background-color: $light;
        border-color: $light;
        color: $dark;
      }
    }
    &.selected {
      &,
      &:hover,
      &:focus {
        background-color: $primary;
        border-color: $primary;
        color: $white;
      }
    }
  }
  &.arrowTop,
  &.arrowBottom {
    &:before,
    &:after {
      border: 0;
    }
    &:before {
      background-image: url('../../../img/triangle.svg');
      background-size: cover;
      background-position: 50% 0;
      width: 20px;
      height: 10px;
      background-repeat: no-repeat;
    }
  }
  &.arrowBottom {
    &:before {
      transform: scaleY(-1);
    }
  }
}
