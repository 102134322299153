.dropdown {
  .dropdown-menu {
    border: solid 1px $dropdown-border-color;
    padding: 0;
    li {
      .dropdown-item {
        padding: $dropdown-padding-x $dropdown-padding-y;
        font-weight: $font-weight-semibold;
        @include font-size($font-size-base);
        line-height: 1.5;
        letter-spacing: $letter-spacing;
      }
      &:not(:last-child) {
        .dropdown-item {
          border-bottom: solid 1px $dropdown-divider-bg;
        }
      }
    }
  }
}
.dropdown-item {
  white-space: normal;
}

.dropdown.js-autocomplete-results-list {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 10;
  display: none;
  &.show {
    display: block;
    background-color: $white;
    border-radius: $dropdown-border-radius;
    padding: $dropdown-padding-y 0;
    margin: 0;
    list-style-type: none;
    box-shadow: 0 0 20px 0 rgba($dark, 0.15);
    li {
      a,
      div {
        @include font-size($input-font-size);
        display: block;
        padding: $dropdown-padding-y * 0.5 $input-padding-x;
      }
      div {
        color: $dropdown-header-color;
      }
      a {
        color: $dropdown-color;
        &:hover,
        &:focus {
          color: $dropdown-link-active-color;
        }
      }
    }
  }
}
