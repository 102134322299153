#breadcrumbs {
  margin: 0 0 1rem 0;
  li {
    &:before {
      height: 13px;
      position: relative;
      top: 3px;
    }
    span,
    a {
      letter-spacing: $letter-spacing;
      font-weight: $font-weight-semibold;
    }
    a {
      color: $breadcrumb-divider-color;
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
