.nav-social {

  $social-size: 2rem;
  $social-size-large: 2.5rem;

  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;

  a {
    background-color: $navbar-dark-color;
    height: $social-size;
    width: $social-size;
    border-radius: $social-size * 0.5;
    .icon {
      fill: $navbar-light-color;
    }
    &:hover,
    &:focus {
      .icon {
        fill: $primary;
      }
    }
  }

  &.nav-light {
    a {
      background-color: $navbar-light-color;
      .icon {
        fill: $navbar-dark-color;
      }
      &:hover,
      &:focus {
        background-color: $primary;
      }
    }
  }
  &.nav-social-large {
    gap: 1.25rem;
    a {
      height: $social-size-large;
      width: $social-size-large;
      border-radius: $social-size-large * 0.5;
    }
  }

}
