.cms-text {
	p,
  .p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	ul,
	ol,
	table {
		margin-top: 0;
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

  h4,
  h5 {
    margin-bottom: 1rem;
    color: $primary;
  }

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	li {
		letter-spacing: $letter-spacing;
	}

	p {
		line-height: 2;
	}

  ul,
  ol {
    padding-left: 1rem;
    li + li {
      margin-top: 0.8rem;
    }

    ul,
    ol {
      &,
      &:last-child {
        margin: 30px 0;
        padding: 0 0 0 30px;
        li {
          &:before {
            color: $dark;
          }
        }
      }
    }

  }

  ul {
    list-style: none;
    li::before {
      content: "\2022";
      color: $primary;
      font-weight: $font-weight-medium;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  strong {
    font-weight: $font-weight-semibold;
  }

  a,
  a strong {
    color: $primary;
    font-weight: $font-weight-semibold;
  }

}

[class*='display-'] {
	letter-spacing: $letter-spacing;
}

h2,
.h2,
h3,
.h3,
h4,
.h4 {
	letter-spacing: $letter-spacing;
	font-family: $font-family-base;
	line-height: $line-height-base;
  &.ff-heading {
    letter-spacing: 0;
  }
}

.ls-1 {
  letter-spacing: $letter-spacing;
}
