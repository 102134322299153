main#page-blog-overview {
  .l-filter-bar {
    #filter-form {
      > div {
        gap: 10px;
        @include media-breakpoint-up(sm) {
          justify-content: flex-end;
        }
      }
    }
    .dropdown-menu {
      @include media-breakpoint-down(md) {
        width: 100%;
        top: 8px !important;
      }
    }
  }
}
