.list-group-faq {
  .list-group-item {
    &:hover,
    &:focus,
    &.active {
     color: $primary;
      background-color: $white;
      .icon {
        stroke: $primary;
      }
    }
  }
}
